import './App.css'
import 'animate.css'

import { useState, useEffect, useRef } from "react"
import logo from './assets/logo.webp'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
} from "react-router-dom";

import styles from './pages/HomePage/video.module.css'
import video1 from './assets/videos/1.mp4'
import video2 from './assets/videos/2.mp4'
import video3 from './assets/videos/3.mp4'
import video4 from './assets/videos/4.mp4'
import video5 from './assets/videos/5.mp4'
import video6 from './assets/videos/6.mp4'
import video7 from './assets/videos/7.mp4'
import video8 from './assets/videos/8.mp4'
import video9 from './assets/videos/9.mp4'
import video10 from './assets/videos/10.mp4'
import video11 from './assets/videos/11.mp4'
import video12 from './assets/videos/12.mp4'
import video13 from './assets/videos/13.mp4'
import video14 from './assets/videos/14.mp4'
import video15 from './assets/videos/15.mp4'
import video16 from './assets/videos/16.mp4'

import AboutUs from "./pages/AboutusPage/AboutUsPage"
import HomePage from "./pages/HomePage/HomePage"
import Pricing from './pages/Pricing/Pricing';
import Product from './pages/Products/Product';
import Features from './pages/Features/Features';
import ContactUs from './pages/ContactUsPage/ContactUsPage';
import PrivacyPolicy from './pages/PrivacyPolicyPage/PrivacyPolicyPage';
import { Rect, useRect } from 'react-use-rect';
import { stack as Menu } from 'react-burger-menu'

const App = () => {
    const [activeVideo, setActiveVideo] = useState<string>('video1')
    const [quitAnimation, setQuitAnimation] = useState<boolean>(false)
    const [logoDisable, setlogoDisable] = useState<boolean>(false);
    const [target, setTarget] = useState<string>('');
    const [currentItemIndex, setCurrentItemIndex] = useState(0);
    const [visible, setVisible] = useState<boolean>(false);
    const [animatonVisible, setAnimatonVisible] = useState<boolean>(false);
    const [rect, setRect] = useState<Rect | null>(null);
    const [rectRef] = useRect(setRect);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const menuRef = useRef<any>(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const words = ["Automated", "End-to-End", "Tailored", "Plug'n Play", "Futuristic"];
    const colors = ["#ffd350", "#ff7a54", "#72c2d2", "#b9d148", "#c961c3"];

    const videoRef1 = useRef<HTMLVideoElement | null>(null);
    const videoRef2 = useRef<HTMLVideoElement | null>(null);
    const videoRef3 = useRef<HTMLVideoElement | null>(null);
    const videoRef4 = useRef<HTMLVideoElement | null>(null);
    const videoRef5 = useRef<HTMLVideoElement | null>(null);
    const videoRef6 = useRef<HTMLVideoElement | null>(null);
    const videoRef7 = useRef<HTMLVideoElement | null>(null);
    const videoRef8 = useRef<HTMLVideoElement | null>(null);
    const videoRef9 = useRef<HTMLVideoElement | null>(null);
    const videoRef10 = useRef<HTMLVideoElement | null>(null);
    const videoRef11 = useRef<HTMLVideoElement | null>(null);
    const videoRef12 = useRef<HTMLVideoElement | null>(null);
    const videoRef13 = useRef<HTMLVideoElement | null>(null);
    const videoRef14 = useRef<HTMLVideoElement | null>(null);
    const videoRef15 = useRef<HTMLVideoElement | null>(null);
    const videoRef16 = useRef<HTMLVideoElement | null>(null);

    const location = useLocation();

    useEffect(() => {
        // Burası url'den hangi sayfa açılırsa o sayfanın aktif videosunu belirliyor
        if (windowSize.width > 1023) {
            switch (location.pathname) {
                case '/about':
                    setlogoDisable(false);
                    break;
                case '/enterprise':
                    
                    setActiveVideo('video3');
                    if(videoRef3.current) {
                        videoRef3.current.autoplay= true;
                        videoRef3.current.loop=true;
                    }
                    break;
                case '/sports':
                    
                    setActiveVideo('video6');
                    if(videoRef6.current) {
                        videoRef6.current.autoplay= true;
                        videoRef6.current.loop=true;
                    }
                    break;
                case '/gaming':
                    
                    setActiveVideo('video11');
                    if(videoRef11.current) {
                        videoRef11.current.autoplay= true;
                        videoRef11.current.loop=true;
                    }
                    break;
                default:
                    break;
            }
        } else {
            setActiveVideo('video6');
            if(videoRef6.current) {
                videoRef6.current.autoplay= true;
                videoRef6.current.loop=true;
            }
            document.getElementById('responsiveVideo')!.style.scale = '3';
            document.getElementById('responsiveVideo')!.style.marginRight = '84vh';
            
        }
    }, [location.pathname, windowSize.width])

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentItemIndex((index) =>
                index === words.length - 1 ? 0 : index + 1
            );
            }, 2000);
        return () => clearInterval(interval);
    }, [currentItemIndex, words]);

    useEffect(() => {
        setTimeout(() => {
            setVisible(true);
        }, 500);
    
    }, [visible])

    useEffect(() => {
        setTimeout(() => {
            setAnimatonVisible(true);
        }, 1000);
    
    }, [visible])

    useEffect(() => {
        if(quitAnimation) {
            setTimeout(() => {
                setQuitAnimation(false);
                setlogoDisable(false);
            }, 4200);
        }
    }, [quitAnimation])
    
    useEffect(() => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }, []);

    const handleVideoEnd = () => {
        //Ana sayfanın videosunun bitişinde geçiş videosunu başlatıyor
        switch (target) {
            case '/enterprise':
                setActiveVideo('video2');
                
                if(videoRef2.current) {
                    videoRef2.current.play();
                }

                break;
            case '/sports':
                setActiveVideo('video8');
                
                if(videoRef8.current) {
                    videoRef8.current.play();
                }

                break;
            case '/gaming':
                setActiveVideo('video16');
                
                if(videoRef16.current) {
                    videoRef16.current.play();
                }

                break;
        
            default:
                break;
        }
    };

    const transactionVideoEnd = () => {
        //ilk geçiş videosunun bitiminde pricing videosunu başlatıp döngüye alıyor
        if(videoRef3.current) {
            videoRef3.current.play();
            videoRef3.current.loop = true;
        }
        
        setActiveVideo('video3');
    }

    const handlePricingVideoEnd = () => {
        // Pricing'den anasayfaya geçerken ki geçiş videosunu başlatacak ve bittiğinde anasayfa videosunu başlatıp döngüye alıcak
        switch (target) {
            case '/':
                setActiveVideo('video4');
                if(videoRef4.current) {
                    videoRef4.current.play();
                }

                break;
            case '/about':
                setActiveVideo('video4');
                if(videoRef4.current) {
                    videoRef4.current.play();
                }

                break;
            case '/sports':
                setActiveVideo('video5');
                if(videoRef5.current) {
                    videoRef5.current.play();
                }

                break;
            case '/gaming':
                setActiveVideo('video10');
                if(videoRef10.current) {
                    videoRef10.current.play();
                }
                    break;
            case '/contact':
                setActiveVideo('video4');
                if(videoRef4.current) {
                    videoRef4.current.play();
                }

                break;
            default:
                break;
        }
    }

    const pricingToProductVideoEnd = () => {
        if(videoRef6.current) {
            videoRef6.current.play();
            videoRef6.current.loop = true;
        }
        
        setActiveVideo('video6');
    }

    const productToPricingVideoEnd = () => {
        if(videoRef3.current) {
            videoRef3.current.play();
            videoRef3.current.loop = true;
        }
        
        setActiveVideo('video3');
    }

    const handleBackTransactionVideoEnd = () => {
        // Pricing'den anasayfaya geçerken geçiş videosu bittiğinde çalışacak.
        if(videoRef1.current) {
            videoRef1.current.play();
            videoRef1.current.loop= true;
        }
        
        setActiveVideo('video1');
    }

    const handleProductVideoEnd = () => {
        switch (target) {
            case'/':
            setActiveVideo('video9');
                if(videoRef9.current) {
                    videoRef9.current.play();
                }
                break;
            case'/about':
            setActiveVideo('video9');
                if(videoRef9.current) {
                    videoRef9.current.play();
                }
                break;
            case '/enterprise':
                setActiveVideo('video7');
                if(videoRef7.current) {
                    videoRef7.current.play();
                }
                
                break;  
            case '/gaming':
                setActiveVideo('video14');
                if(videoRef14.current) {
                    videoRef14.current.play();
                }
                
                break;   
            case'/contact':
                setActiveVideo('video9');
                    if(videoRef9.current) {
                        videoRef9.current.play();
                    }
                break;
            default:
                break;
        }
    }

    const handleFeaturesVideoEnd = () => {
        switch (target) {
            case '/':
                setActiveVideo('video13');
                if(videoRef13.current) {
                    videoRef13.current.play();
                }
                break;
            case '/about':
                setActiveVideo('video13');
                if(videoRef13.current) {
                    videoRef13.current.play();
                }
                break;
            case '/sports':
                setActiveVideo('video15');
                if(videoRef15.current) {
                    videoRef15.current.play();
                }
                break;
            case '/enterprise':
                setActiveVideo('video12');
                if(videoRef12.current) {
                    videoRef12.current.play();
                }
                break;
            case '/contact':
                setActiveVideo('video13');
                if(videoRef13.current) {
                    videoRef13.current.play();
                }
                break;
            default:
                break;
        }
    }

    const aboutToProductVideoEnd = () => {
        if(videoRef6.current) {
            videoRef6.current.play();
            videoRef6.current.loop = true;
        }
        setActiveVideo('video6');
    }

    const productToAboutVideoEnd = () => {
        setActiveVideo('video1');
        if(videoRef1.current) {
            videoRef1.current.play();
            videoRef1.current.loop = true;
        }
    }

    const pricingToFeaturesVideoEnd = () => {
        if(videoRef11.current) {
            videoRef11.current.play();
            videoRef11.current.loop = true;
        }
        setActiveVideo('video11');
    }

    const featuresToPricingVideoEnd = () => {
        if(videoRef3.current) {
            videoRef3.current.play();
            videoRef3.current.loop = true;
        }
            setActiveVideo('video3');
    }

    const featuresToAboutUsVideoEnd = () => {
        setActiveVideo('video1')
        if(videoRef1.current) {
            videoRef1.current.play();
            videoRef1.current.loop = true;
        }
    }

    const productToFeaturesVideoEnd = () => {
        if(videoRef11.current) {
            videoRef11.current.play();
            videoRef11.current.loop = true;
        }
        setActiveVideo('video11');
    }

    const featuresToProductVideoEnd = () => {
        if(videoRef6.current) {
            videoRef6.current.play();
            videoRef6.current.loop = true;
        }
        setActiveVideo('video6');
    }

    const aboutToFeaturesVideoEnd = () => {
        if(videoRef11.current) {
            videoRef11.current.play();
            videoRef11.current.loop = true;
        }
        
        setActiveVideo('video11');
    }

    const changePage = (to:string) => {
        setIsOpen(false);
        if (windowSize.width > 1023) {
            if(window.location.pathname !== to) {
                //! İlk önce to methodu çalıştığı için yenileme olmuyor
                // window.location.reload();
                setlogoDisable(true);
                setQuitAnimation(true);
                switch(activeVideo) {
                    case 'video1' :
                        if(videoRef1.current) {
                            if(to !== '/contact' && to !== '/about' && to !== '/' ) {
                                videoRef1.current.loop = false
                            } 
                        }
                        break
                    case 'video3': 
                        if(to === '/') {
                            setlogoDisable(false);
                        }
                        if(videoRef3.current) {
                            videoRef3.current.loop = false 
                        }
                        break
                    case 'video6': 
                        if(videoRef6.current) {
                            videoRef6.current.loop = false 
                        }
                        break
                    case 'video11': 
                        if(videoRef11.current) {
                            videoRef11.current.loop = false 
                        }
                        break
                    default:
                        break;
                }
            } 
        }
    }

    useEffect(() => {
        const handleClickOutside = (event:any) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <div className="App">
            <Router>
                    {
                        windowSize.width > 1023 ? (
                            <div className={styles.navigation_bar} >
                            <Link 
                            to='/' 
                            className={logoDisable ? styles.logo_disable : 'null'} 
                            onClick={() => 
                                    {
                                    !logoDisable &&
                                        changePage('/'); 
                                        setTarget('/');
                                    }
                                }
                                >
                                <img 
                                    className={styles.logo}
                                    src={logo} alt="logo" 
                                />
                                {window.location.pathname === '/' &&
                            (
                                <>
                                    <div
                                    className={`${styles.animation_container} ${target === '/' ? null : !quitAnimation ? 'animate__animated animate__fadeInDown': 'animate__animated animate__fadeOutUp' }`}
                                    style={{
                                    width: `${rect?.width && rect.width * 1.2}px`,
                                    maxWidth: `${rect?.width && rect.width * 1.2}px`,
                                    pointerEvents:'none',
                                    animationDelay:'2s'
                                    }}
                                >
                                    
                                    <span style={{ cursor: 'pointer', visibility: "hidden" ,display: `${animatonVisible ? 'block' : 'none'}`}}>{words[currentItemIndex]}</span>
                                    {words.map((text, index) => (
                                    <span
                                        key={index}
                                        ref={currentItemIndex === index ? rectRef : null}
                                        style={{
                                            position: "absolute",
                                            top: (rect?.height ?? 0) * 2,
                                            transform: `translateY(${
                                                currentItemIndex === index ? `-${(rect?.height ?? 0) * 2}px` : 0
                                        })`,
                                        transition: "all 1s ease-in-out",
                                        color:`${colors[index]}`
                                        }}
                                    >
                                        {text}
                                    </span>
                                    )) }
                                </div>
                            </>
                            )
                        }
                            </Link>
                            <div className={`${styles.navigation_buttons} ${target === '/about' ? null : !quitAnimation ? 'animate__animated animate__fadeInDown': 'animate__animated animate__fadeOutUp' }`}  
                            style={!quitAnimation 
                                        ? {cursor: 'pointer',animationDuration:'0.3s', } 
                                        : {cursor: 'pointer',animationDuration: '0.3s',}}>
                                <Link to='/about' onClick={() => {
                                    changePage('/about'); 
                                    setTarget('/about');
                                    }}
                                    style={window.location.pathname === '/about' || quitAnimation ? {pointerEvents: 'none'} : {pointerEvents: 'auto'}}
                                >
                                    About Us
                                </Link>
                            </div>
                            <div className={`${styles.navigation_buttons} ${target === '/enterprise' ? null : !quitAnimation ? 'animate__animated animate__fadeInDown animate_slow': 'animate__animated animate__fadeOutUp animate_slow' }`}  
                            style={!quitAnimation 
                                        ? {cursor: 'pointer',animationDuration:'0.3s', } 
                                        : {cursor: 'pointer',animationDuration: '0.3s',}}>
                                <Link to={'/enterprise'} onClick={() => {
                                    changePage('/enterprise'); 
                                    setTarget('/enterprise');
                                    }}
                                    style={window.location.pathname === '/enterprise'|| quitAnimation  ? {pointerEvents: 'none'} : {pointerEvents: 'auto'}}
                                >
                                    Enterprise
                                </Link>
                            </div>
                            <div className={`${styles.navigation_buttons} ${target === '/sports' ? null : !quitAnimation ? 'animate__animated animate__fadeInDown animate_slower': 'animate__animated animate__fadeOutUp animate_slower' }`}  
                            style={!quitAnimation 
                                        ? {cursor: 'pointer',animationDuration:'0.3s', } 
                                        : {cursor: 'pointer',animationDuration: '0.3s',}}>
                                <Link to={'/sports'} onClick={() => {
                                    changePage('/sports'); 
                                    setTarget('/sports');
                                    }}
                                    style={window.location.pathname === '/sports'|| quitAnimation  ? {pointerEvents: 'none'} : {pointerEvents: 'auto'}}
                                >
                                    Sports
                                </Link>
                            </div>
                            <div className={`${styles.navigation_buttons} ${target === '/gaming' ? null : !quitAnimation ? 'animate__animated animate__fadeInDown animate_slower': 'animate__animated animate__fadeOutUp animate_slower' }`}  
                            style={!quitAnimation 
                                        ? {cursor: 'pointer',animationDuration:'0.3s', } 
                                        : {cursor: 'pointer',animationDuration: '0.3s',}}>
                                <Link to={'/gaming'} onClick={() => {
                                    changePage('/gaming'); 
                                    setTarget('/gaming');
                                    }}
                                    style={window.location.pathname === '/gaming'|| quitAnimation  ? {pointerEvents: 'none'} : {pointerEvents: 'auto'}}
                                >
                                    Gaming
                                </Link>
                            </div>
                                <div className={`${styles.right_buttons_container}`}>
                                    <div className={`${styles.right_buttons} ${target === '/contact' ? null : !quitAnimation ? 'animate__animated animate__fadeInDown animate_slow': 'animate__animated animate__fadeOutUp animate_slow' }`}  
                                    style={!quitAnimation 
                                        ? {cursor: 'pointer',animationDuration:'0.3s', } 
                                        : {cursor: 'pointer',animationDuration: '0.3s',}}>
                                        <Link to={'/contact'} onClick={() => {
                                            changePage('/contact'); 
                                            setTarget('/contact');
                                            }}
                                            style={window.location.pathname === '/contact' || quitAnimation  ? {pointerEvents: 'none'} : {pointerEvents: 'auto'}}
                                        >
                                            Contact Us
                                        </Link>
                                    </div>
                                </div>
                        </div>
                        ) : (
                            <>
                                <img 
                                    className={styles.hamburgerLogo}
                                    src={logo} alt="logo" 
                                />
                                <Menu noOverlay isOpen={isOpen} onStateChange={(state) => setIsOpen(state.isOpen)} ref={menuRef}>
                                    <Link to='/' onClick={() => {
                                            changePage('/'); 
                                            setTarget('/');
                                            }}
                                            style={window.location.pathname === '/' || quitAnimation ? {pointerEvents: 'none'} : {pointerEvents: 'auto'}}
                                        >
                                            Home
                                    </Link>
                                    <Link to='/about' onClick={() => {
                                        changePage('/about'); 
                                        setTarget('/about');
                                        }}
                                        style={window.location.pathname === '/about' || quitAnimation ? {pointerEvents: 'none'} : {pointerEvents: 'auto'}}
                                    >
                                        About Us
                                    </Link>
                                    <Link to='/enterprise' onClick={() => {
                                        changePage('/enterprise'); 
                                        setTarget('/enterprise');
                                        }}
                                        style={window.location.pathname === '/enterprise' || quitAnimation ? {pointerEvents: 'none'} : {pointerEvents: 'auto'}}
                                    >
                                        Enterprise
                                    </Link>
                                    <Link to='/sports' onClick={() => {
                                        changePage('/sports'); 
                                        setTarget('/sports');
                                        }}
                                        style={window.location.pathname === '/sports' || quitAnimation ? {pointerEvents: 'none'} : {pointerEvents: 'auto'}}
                                    >
                                        Sports
                                    </Link>
                                    <Link to='/gaming' onClick={() => {
                                        changePage('/gaming'); 
                                        setTarget('/gaming');
                                        }}
                                        style={window.location.pathname === '/gaming' || quitAnimation ? {pointerEvents: 'none'} : {pointerEvents: 'auto'}}
                                    >
                                        Gaming
                                    </Link>
                                    <Link to='/contact' onClick={() => {
                                        changePage('/contact'); 
                                        setTarget('/contact');
                                        }}
                                        style={window.location.pathname === '/contact' || quitAnimation ? {pointerEvents: 'none'} : {pointerEvents: 'auto'}}
                                    >
                                        Contact
                                    </Link>
                                </Menu>
                            </>
                        ) 
                    }
                    {/* Ana sayfa videosu*/}
                    <video 
                    autoPlay
                    ref={videoRef1}
                    onEnded={handleVideoEnd}
                    loop
                    preload="auto"
                    muted
                    playsInline
                    id="myVideo" style={activeVideo === 'video1' ?  { visibility: 'visible' }: { visibility: 'hidden', display: 'none' }}>
                        <source src={video1} type="video/mp4" />
                    </video>
                    {/* Anasyafadan prcing'e geçiş videosu*/}
                    <video 
                    ref={videoRef2}
                    onEnded={transactionVideoEnd}
                    preload="auto"
                    muted
                    playsInline
                    id="myVideo" style={activeVideo === 'video2' ? { visibility: 'visible' } : { visibility: 'hidden',display: 'none' }}>
                        <source src={video2} type="video/mp4" />
                    </video>
                    {/* Pricing videosu*/}
                    <video 
                    ref={videoRef3}
                    onEnded={handlePricingVideoEnd}
                    preload="auto"
                    muted
                    playsInline
                    id="myVideo" style={activeVideo === 'video3' ? { visibility: 'visible' } : { visibility: 'hidden', display: 'none' }}>
                        <source src={video3} type="video/mp4" />
                    </video>
                    {/* Pricing'den anasayfaya geçiş videosu*/}
                    <video 
                    ref={videoRef4}
                    onEnded={handleBackTransactionVideoEnd}
                    preload="auto"
                    muted
                    playsInline
                    id="myVideo" style={activeVideo === 'video4' ? { visibility: 'visible' } : { visibility: 'hidden', display: 'none' }}>
                        <source src={video4} type="video/mp4" />
                    </video>
                    {/* pricing'den prodcut'ageçişi sağlıyor */}
                    <video 
                    ref={videoRef5}
                    onEnded={pricingToProductVideoEnd}
                    preload="auto"
                    muted
                    playsInline
                    id="myVideo" style={activeVideo === 'video5' ? { visibility: 'visible' } : { visibility: 'hidden', display: 'none' }}>
                        <source src={video5} type="video/mp4" />
                    </video>
                    {/* Product loop videosu */}
                    <video
                    ref={videoRef6}
                    onEnded={handleProductVideoEnd}
                    preload="auto"
                    muted
                    playsInline
                    id="responsiveVideo"  className={styles.responsive_video} style={activeVideo === 'video6' ? { visibility: 'visible' } : { visibility: 'hidden', display: 'none' }}>
                        <source src={`${video6}#t=0.1`} type="video/mp4" />
                    </video>
                    {/* Product'dan pricing'e geçişi sağlıyor */}
                    <video 
                    ref={videoRef7}
                    onEnded={productToPricingVideoEnd}
                    preload="auto"
                    muted
                    playsInline
                    id="myVideo" style={activeVideo === 'video7' ? { visibility: 'visible' } : { visibility: 'hidden', display: 'none' }}>
                        <source src={video7} type="video/mp4" />
                    </video>
                    {/* Anasayfa ve aboutus'den products'a geçiş videosu bu geçiş tamamlandınca video6'ya geçicek */}
                    <video 
                    ref={videoRef8}
                    onEnded={aboutToProductVideoEnd}
                    preload="auto"
                    muted
                    playsInline
                    id="myVideo" style={activeVideo === 'video8' ? { visibility: 'visible' } : { visibility: 'hidden', display: 'none' }}>
                        <source src={video8} type="video/mp4" />
                    </video>
                    {/* Productan about us ve anasayfaya geçiş */}
                    <video 
                    ref={videoRef9}
                    onEnded={productToAboutVideoEnd}
                    preload="auto"
                    muted
                    playsInline
                    id="myVideo" style={activeVideo === 'video9' ? { visibility: 'visible' } : { visibility: 'hidden', display: 'none' }}>
                        <source src={video9} type="video/mp4" />
                    </video>
                    {/* Pricing'den feature'a geçiş */}
                    <video 
                    ref={videoRef10}
                    onEnded={pricingToFeaturesVideoEnd}
                    preload="auto"
                    muted
                    playsInline
                    id="myVideo" style={activeVideo === 'video10' ? { visibility: 'visible' } : { visibility: 'hidden', display: 'none' }}>
                        <source src={video10} type="video/mp4" />
                    </video>
                    {/* Features videosu */}
                    <video 
                    ref={videoRef11}
                    onEnded={handleFeaturesVideoEnd}
                    preload="auto"
                    muted
                    playsInline
                    id="myVideo" style={activeVideo === 'video11' ? { visibility: 'visible' } : { visibility: 'hidden', display: 'none' }}>
                        <source src={video11} type="video/mp4" />
                    </video>
                    {/* features'den Pricing'e geçiş videosu */}
                    <video 
                    ref={videoRef12}
                    onEnded={featuresToPricingVideoEnd}
                    preload="auto"
                    muted
                    playsInline
                    id="myVideo" style={activeVideo === 'video12' ? { visibility: 'visible' } : { visibility: 'hidden', display: 'none' }}>
                        <source src={video12} type="video/mp4" />
                    </video>
                    {/* Features'den aboutUs ve anasayfa geçişi */}
                    <video 
                    ref={videoRef13}
                    onEnded={featuresToAboutUsVideoEnd}
                    preload="auto"
                    muted
                    playsInline
                    id="myVideo" style={activeVideo === 'video13' ? { visibility: 'visible' } : { visibility: 'hidden', display: 'none' }}>
                        <source src={video13} type="video/mp4" />
                    </video>
                    <video 
                    ref={videoRef14}
                    onEnded={productToFeaturesVideoEnd}
                    preload="auto"
                    muted
                    playsInline
                    id="myVideo" style={activeVideo === 'video14' ? { visibility: 'visible' } : { visibility: 'hidden', display: 'none' }}>
                        <source src={video14} type="video/mp4" />
                    </video>
                    {/* Features'den product'a geçiş */}
                    <video 
                    ref={videoRef15}
                    onEnded={featuresToProductVideoEnd}
                    preload="auto"
                    muted
                    playsInline
                    id="myVideo" style={activeVideo === 'video15' ? { visibility: 'visible' } : { visibility: 'hidden', display: 'none' }}>
                        <source src={video15} type="video/mp4" />
                    </video>
                    <video 
                    ref={videoRef16}
                    onEnded={aboutToFeaturesVideoEnd}
                    preload="auto"
                    muted
                    playsInline
                    id="myVideo" style={activeVideo === 'video16' ? { visibility: 'visible' } : { visibility: 'hidden', display: 'none' }}>
                        <source src={video16} type="video/mp4" />
                    </video>
                    {/* {window.location.pathname === '/' &&
                        (
                            <>
                                <div
                                className={`${styles.animation_container} ${target === '/' ? null : !quitAnimation ? 'animate__animated animate__fadeInDown': 'animate__animated animate__fadeOutUp' }`}
                                style={{
                                width: `${rect?.width && rect.width * 1.1}px`,
                                maxWidth: `${rect?.width && rect.width * 1.1}px`,
                                }}
                            >
                                
                                <span style={{ visibility: "hidden" ,display: `${animatonVisible ? 'block' : 'none'}`}}>{words[currentItemIndex]}</span>
                                {words.map((text, index) => (
                                <span
                                    key={index}
                                    ref={currentItemIndex === index ? rectRef : null}
                                    style={{
                                        position: "absolute",
                                        top: (rect?.height ?? 0) * 2,
                                        transform: `translateY(${
                                            currentItemIndex === index ? `-${(rect?.height ?? 0) * 2}px` : 0
                                    })`,
                                    transition: "all 1s ease-in-out",
                                    color:`${colors[index]}`
                                    }}
                                >
                                    {text}
                                </span>
                                ))}
                            </div>
                        </>
                        )
                    } */}
                    <Switch>
                        <div className={`${styles.aboutus_text}`}>
                            <div className={styles.first_h1}>
                                <Route exact path="/">
                                    <HomePage/> 
                                </Route>
                            </div>
                        </div>
                    </Switch>
                    <Switch>
                        <div className={styles.contact_body}>
                            <Route exact path="/contact">
                                <ContactUs width={windowSize.width}/>         
                            </Route>
                            <Route exact path="/privacy-policy">
                                <PrivacyPolicy width={windowSize.width}/>         
                            </Route>
                        </div>
                    </Switch>
                    <div className={styles.content_body}>
                        <Switch>
                            <Route exact path="/about">
                                <AboutUs  width={windowSize.width} />
                            </Route>
                            <Route exact path="/enterprise">
                                <Pricing  width={windowSize.width} />
                            </Route>
                            <Route exact path="/sports">
                                <Product width={windowSize.width} />
                            </Route>
                            <Route exact path="/gaming">
                                <Features width={windowSize.width}/>
                            </Route>
                        </Switch>
                    </div>
            </Router>
        </div>
    )
}

export default App;