import { FC, useState } from "react";
import styles from '../HomePage/video.module.css'
import 'animate.css'; 
import useDelayRouteExit from "delay-react-route-exit";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper/modules';


export interface IWindowWeight {
    width:number;
}

const Pricing :FC<IWindowWeight> = ({width}) => {

    const [isExitingRoute, setIsExitingRoute] = useState<boolean>();

        // eslint-disable-next-line react-hooks/rules-of-hooks
            useDelayRouteExit(1500, () => {
            setIsExitingRoute(true);
        });

        // Future of the 

        return (
            <> 
                <div className={`${styles.aboutus_text}`}>
                    <div className={styles.first_h1}>
                        <span className={`
                        ${isExitingRoute 
                            ? "animate__animated animate__fadeOutDown" 
                            : "animate__animated animate__fadeInUp"}`} 
                        style={isExitingRoute 
                            ? {animationDelay:'0.6s',animationDuration:'0.7s', display: 'inline-block',fontSize: '97px',fontWeight: 'bold'  } 
                            : {animationDuration: '1.50s', display: 'inline-block', fontSize: '97px',fontWeight: 'bold' }}>
                            F
                        </span>
                        <span className={` ${isExitingRoute ? "animate__animated animate__fadeOutDown": "animate__animated animate__fadeInUp"}`} 
                        style={isExitingRoute 
                            ? {animationDelay:'0.6s',animationDuration:'0.67s', display: 'inline-block',fontSize: '97px', fontWeight: 'bold' } 
                            : {animationDuration: '1.60s', display: 'inline-block', fontSize: '97px',fontWeight: 'bold' }}>
                            u
                        </span>
                        <span className={` ${isExitingRoute ? "animate__animated animate__fadeOutDown": "animate__animated animate__fadeInUp"}`} 
                        style={isExitingRoute 
                            ? {animationDelay:'0.6s',animationDuration:'0.64s', display: 'inline-block',fontSize: '97px', fontWeight: 'bold' } 
                            : {animationDuration: '1.70s', display: 'inline-block', fontSize: '97px',fontWeight: 'bold' }}>
                            t
                        </span>
                        <span className={` ${isExitingRoute ? "animate__animated animate__fadeOutDown": "animate__animated animate__fadeInUp"}`} 
                        style={isExitingRoute 
                            ? {animationDelay:'0.6s',animationDuration:'0.61s', display: 'inline-block',fontSize: '97px', fontWeight: 'bold' } 
                            : {animationDuration: '1.80s', display: 'inline-block', fontSize: '97px',fontWeight: 'bold' }}>
                            u
                        </span>
                        <span className={` ${isExitingRoute ? "animate__animated animate__fadeOutDown": "animate__animated animate__fadeInUp"}`} 
                        style={isExitingRoute 
                            ? {animationDelay:'0.6s',animationDuration:'0.58s', display: 'inline-block',fontSize: '97px', fontWeight: 'bold' } 
                            : {animationDuration: '1.90s', display: 'inline-block', fontSize: '97px',fontWeight: 'bold' }}>
                            r
                        </span>
                        <span className={` ${isExitingRoute ? "animate__animated animate__fadeOutDown": "animate__animated animate__fadeInUp"}`} 
                        style={isExitingRoute 
                            ? {animationDelay:'0.6s',animationDuration:'0.55s', display: 'inline-block',fontSize: '97px', fontWeight: 'bold' } 
                            : {animationDuration: '2s', display: 'inline-block', fontSize: '97px',fontWeight: 'bold' }}>
                            e
                        </span>
                        <span className={`${styles.first_h1} ${isExitingRoute ? "animate__animated animate__fadeOutDown" : "animate__animated animate__fadeInUp"}`}
                            style={isExitingRoute
                            ? {marginLeft:'20px',animationDelay:'0.6s', animationDuration: '0.52s', display: 'inline-block',fontSize: '97px', fontWeight: 'bold' }
                            : {marginLeft:'20px', animationDuration: '2.10s', display: 'inline-block', fontSize: '97px', fontWeight: 'bold' }}>
                            o
                        </span>
                        <span className={`${styles.first_h1} ${isExitingRoute ? "animate__animated animate__fadeOutDown" : "animate__animated animate__fadeInUp"}`}
                        style={isExitingRoute
                            ? {animationDelay:'0.6s', animationDuration: '0.49s', display: 'inline-block',fontSize: '97px', fontWeight: 'bold' }
                            : { animationDuration: '2.20s', display: 'inline-block', fontSize: '97px', fontWeight: 'bold' }}>
                                f
                        </span>
                        <span className={` 
                        ${isExitingRoute 
                            ? "animate__animated animate__fadeOutDown" 
                            : "animate__animated animate__fadeInUp"}`} 
                        style={isExitingRoute 
                            ? {marginLeft:'20px',animationDelay:'0.6s',animationDuration:'0.46s', display: 'inline-block' ,fontSize: '97px', fontWeight: 'bold' } 
                            : {marginLeft:'20px',animationDuration: '2.30s', display: 'inline-block', fontSize: '97px',fontWeight: 'bold' }}>
                            t
                        </span>
                        <span className={` ${isExitingRoute ? "animate__animated animate__fadeOutDown": "animate__animated animate__fadeInUp"}`} 
                        style={isExitingRoute 
                            ? {animationDelay:'0.6s',animationDuration:'0.43s', display: 'inline-block' ,fontSize: '97px', fontWeight: 'bold'} 
                            : {animationDuration: '2.40s', display: 'inline-block', fontSize: '97px',fontWeight: 'bold' }}>
                            h
                        </span>
                        {/* Buraya bir satur boşluk gelicek */}
                        <span className={` ${isExitingRoute ? "animate__animated animate__fadeOutDown": "animate__animated animate__fadeInUp"}`} 
                        style={isExitingRoute 
                            ? {animationDelay:'0.6s',animationDuration:'0.30s', display: 'inline-block' ,fontSize: '97px', fontWeight: 'bold'} 
                            : {animationDuration: '2.5s', display: 'inline-block', fontSize: '97px',fontWeight: 'bold' }}>
                            e
                        </span>
                    </div>
                        <div className={styles.second_h1}>
                            <span className={`
                            ${isExitingRoute 
                                ? "animate__animated animate__fadeOutDown" 
                                : "animate__animated animate__fadeInUp"}`} 
                            style={isExitingRoute 
                                ? {animationDelay:'0.4s',animationDuration:'0.70s', display: 'inline-block',fontSize: '97px',fontWeight: 'bold'  } 
                                : {animationDelay:'0.5s',animationDuration: '0.70s', display: 'inline-block', fontSize: '97px',fontWeight: 'bold' }}>
                                B
                            </span>
                            <span className={` ${isExitingRoute ? "animate__animated animate__fadeOutDown": "animate__animated animate__fadeInUp"}`} 
                            style={isExitingRoute 
                                ? {animationDelay:'0.4s',animationDuration:'0.67s', display: 'inline-block',fontSize: '97px', fontWeight: 'bold' } 
                                : {animationDelay:'0.5s',animationDuration: '0.80s', display: 'inline-block', fontSize: '97px',fontWeight: 'bold' }}>
                                u
                            </span>
                            <span className={` ${isExitingRoute ? "animate__animated animate__fadeOutDown": "animate__animated animate__fadeInUp"}`} 
                            style={isExitingRoute 
                                ? {animationDelay:'0.4s',animationDuration:'0.64s', display: 'inline-block',fontSize: '97px', fontWeight: 'bold' } 
                                : {animationDelay:'0.5s',animationDuration: '0.90s', display: 'inline-block', fontSize: '97px',fontWeight: 'bold' }}>
                                s
                            </span>
                            <span className={` ${isExitingRoute ? "animate__animated animate__fadeOutDown": "animate__animated animate__fadeInUp"}`} 
                            style={isExitingRoute 
                                ? {animationDelay:'0.4s',animationDuration:'0.61s', display: 'inline-block',fontSize: '97px', fontWeight: 'bold' } 
                                : {animationDelay:'0.5s',animationDuration: '1s', display: 'inline-block', fontSize: '97px',fontWeight: 'bold' }}>
                                i
                            </span>
                            <span className={` ${isExitingRoute ? "animate__animated animate__fadeOutDown": "animate__animated animate__fadeInUp"}`} 
                            style={isExitingRoute 
                                ? {animationDelay:'0.4s',animationDuration:'0.58s', display: 'inline-block',fontSize: '97px', fontWeight: 'bold' } 
                                : {animationDelay:'0.5s',animationDuration: '1.10s', display: 'inline-block', fontSize: '97px',fontWeight: 'bold' }}>
                                n
                            </span>
                            <span className={` ${isExitingRoute ? "animate__animated animate__fadeOutDown": "animate__animated animate__fadeInUp"}`} 
                            style={isExitingRoute 
                                ? {animationDelay:'0.4s',animationDuration:'0.55s', display: 'inline-block',fontSize: '97px', fontWeight: 'bold' } 
                                : {animationDelay:'0.5s',animationDuration: '1.20s', display: 'inline-block', fontSize: '97px',fontWeight: 'bold' }}>
                                e
                            </span>
                            <span className={`${styles.first_h1} ${isExitingRoute ? "animate__animated animate__fadeOutDown" : "animate__animated animate__fadeInUp"}`}
                                style={isExitingRoute
                                    ? {animationDelay:'0.4s', animationDuration: '0.52s', display: 'inline-block',fontSize: '97px', fontWeight: 'bold' }
                                    : {animationDelay:'0.5s', animationDuration: '1.30s', display: 'inline-block', fontSize: '97px', fontWeight: 'bold' }}>
                                s
                            </span>
                            <span className={`${styles.first_h1} ${isExitingRoute ? "animate__animated animate__fadeOutDown" : "animate__animated animate__fadeInUp"}`}
                            style={isExitingRoute
                                ? {animationDelay:'0.4s', animationDuration: '0.49s', display: 'inline-block',fontSize: '97px', fontWeight: 'bold' }
                                : {animationDelay:'0.5s', animationDuration: '1.40s', display: 'inline-block', fontSize: '97px', fontWeight: 'bold' }}>
                                s
                            </span>
                            <span className={` 
                            ${isExitingRoute 
                                ? "animate__animated animate__fadeOutDown" 
                                : "animate__animated animate__fadeInUp"}`} 
                            style={isExitingRoute 
                                ? {marginLeft:'20px',animationDelay:'0.4s',animationDuration:'0.46s', display: 'inline-block' ,fontSize: '97px', fontWeight: 'bold' } 
                                : {marginLeft:'20px',animationDelay:'0.5s',animationDuration: '1.50s', display: 'inline-block', fontSize: '97px',fontWeight: 'bold' }}>
                                M
                            </span>
                            <span className={` ${isExitingRoute ? "animate__animated animate__fadeOutDown": "animate__animated animate__fadeInUp"}`} 
                            style={isExitingRoute 
                                ? {animationDelay:'0.4s',animationDuration:'0.43s', display: 'inline-block' ,fontSize: '97px', fontWeight: 'bold'} 
                                : {animationDelay:'0.5s',animationDuration: '1.60s', display: 'inline-block', fontSize: '97px',fontWeight: 'bold' }}>
                                a
                            </span>
                            <span className={` 
                            ${isExitingRoute 
                                ? "animate__animated animate__fadeOutDown" 
                                : "animate__animated animate__fadeInUp"}`} 
                            style={isExitingRoute 
                                ? {animationDelay:'0.4s',animationDuration:'0.40s', display: 'inline-block' ,fontSize: '97px', fontWeight: 'bold' } 
                                : {animationDelay:'0.5s',animationDuration: '1.70s', display: 'inline-block', fontSize: '97px',fontWeight: 'bold' }}>
                                n
                            </span>
                            <span className={` ${isExitingRoute ? "animate__animated animate__fadeOutDown": "animate__animated animate__fadeInUp"}`} 
                            style={isExitingRoute 
                                ? {animationDelay:'0.4s',animationDuration:'0.37s', display: 'inline-block' ,fontSize: '97px', fontWeight: 'bold'} 
                                : {animationDelay:'0.5s',animationDuration: '1.80s', display: 'inline-block', fontSize: '97px',fontWeight: 'bold' }}>
                                a
                            </span>
                            <span className={` 
                            ${isExitingRoute 
                                ? "animate__animated animate__fadeOutDown" 
                                : "animate__animated animate__fadeInUp"}`} 
                            style={isExitingRoute 
                                ? {animationDelay:'0.4s',animationDuration:'0.34s', display: 'inline-block' ,fontSize: '97px', fontWeight: 'bold' } 
                                : {animationDelay:'0.5s',animationDuration: '1.90s', display: 'inline-block', fontSize: '97px',fontWeight: 'bold' }}>
                                g
                            </span>
                            <span className={` ${isExitingRoute ? "animate__animated animate__fadeOutDown": "animate__animated animate__fadeInUp"}`} 
                            style={isExitingRoute 
                                ? {animationDelay:'0.4s',animationDuration:'0.31s', display: 'inline-block' ,fontSize: '97px', fontWeight: 'bold'} 
                                : {animationDelay:'0.5s',animationDuration: '2s', display: 'inline-block', fontSize: '97px',fontWeight: 'bold' }}>
                                e
                            </span>
                            <span className={` ${isExitingRoute ? "animate__animated animate__fadeOutDown": "animate__animated animate__fadeInUp"}`} 
                            style={isExitingRoute 
                                ? {animationDelay:'0.4s',animationDuration:'0.28s', display: 'inline-block' ,fontSize: '97px', fontWeight: 'bold'} 
                                : {animationDelay:'0.5s',animationDuration: '2.20s', display: 'inline-block', fontSize: '97px',fontWeight: 'bold' }}>
                                m
                            </span>
                            <span className={` ${isExitingRoute ? "animate__animated animate__fadeOutDown": "animate__animated animate__fadeInUp"}`} 
                            style={isExitingRoute 
                                ? {animationDelay:'0.4s',animationDuration:'0.25s', display: 'inline-block' ,fontSize: '97px', fontWeight: 'bold'} 
                                : {animationDelay:'0.5s',animationDuration: '2.30s', display: 'inline-block', fontSize: '97px',fontWeight: 'bold' }}>
                                e
                            </span>
                            <span className={` ${isExitingRoute ? "animate__animated animate__fadeOutDown": "animate__animated animate__fadeInUp"}`} 
                            style={isExitingRoute 
                                ? {animationDelay:'0.4s',animationDuration:'0.23s', display: 'inline-block' ,fontSize: '97px', fontWeight: 'bold'} 
                                : {animationDelay:'0.5s',animationDuration: '2.40s', display: 'inline-block', fontSize: '97px',fontWeight: 'bold' }}>
                                n
                            </span>
                            <span className={` ${isExitingRoute ? "animate__animated animate__fadeOutDown": "animate__animated animate__fadeInUp"}`} 
                            style={isExitingRoute 
                                ? {animationDelay:'0.4s',animationDuration:'0.20s', display: 'inline-block' ,fontSize: '97px', fontWeight: 'bold'} 
                                : {animationDelay:'0.5s',animationDuration: '2.5s', display: 'inline-block', fontSize: '97px',fontWeight: 'bold' }}>
                                t
                            </span>
                    </div>
                    <h6 className={`${styles.subText} ${isExitingRoute ? "animate__animated animate__fadeOutLeft": "animate__animated animate__fadeInLeft"}`} 
                    style={isExitingRoute ? { animationDelay:'0.3s',animationDuration:'0.4s' } : { animationDelay:'1.7s',animationDuration: '0.8s' }}>
                        Generation’s next level enterprise solutions redefine possibilities.
                    </h6>
                    <button className={`${styles.demo_button} ${isExitingRoute ? 'animate__animated animate__fadeOutLeft': 'animate__animated animate__fadeInLeft' }`} style={isExitingRoute ? { animationDelay:'0.2s',animationDuration:'0.3s' } : { animationDelay:'2.2s',animationDuration: '0.8s' }}>
                        <Link to={'#'} 
                        // onClick={() => {
                        //     changePage('/order'); 
                        //     setTarget('/order');
                        //     }}
                        >
                            <span>Demo Available Soon</span>
                        </Link>
                    </button>
                </div>
                {
                width > 1023 ? 
                (
            <>
                <div 
            className={`${styles.cards} ${isExitingRoute ? " animate__animated animate__fadeOut " : "animate__animated animate__fadeIn"}`}
            style={isExitingRoute ? {animationDelay:'0.6s',animationDuration:'0.3s'} : {animationDelay:'1.5s', animationDuration: '1s' }}
            >
                <div className={`${styles.horizontal_team} ${isExitingRoute ? " animate__animated animate__fadeOutDown " : "animate__animated animate__fadeInUp"}`} 
                style={isExitingRoute ? { animationDelay:'0.5s',animationDuration:'0.3s' } : {animationDelay:'2.1s', animationDuration: '1s' }} >
                    <div className={styles.team_member}>Fully Automated Talent Acquisition</div>
                    <div className={styles.team_function}>
                    <p>Our end-to-end HR solution streamlines the entire recruitment process. From preparing job ads collaboratively to publishing them across platforms, it simplifies applicant selection through intuitive tools. With live interviewing capabilities and comprehensive scoring systems, it offers a seamless experience for recruiters, ensuring efficient and informed decision-making at every step.</p>
                    </div>
                </div>
                </div>
                <div 
            className={`${styles.cards} ${isExitingRoute ? " animate__animated animate__fadeOut " : "animate__animated animate__fadeIn"}`}
            style={isExitingRoute ? {animationDelay:'0.5s',animationDuration:'0.3s'} : {animationDelay:'1.9s',animationDuration: '1s' }}
            >
                <div className={`${styles.horizontal_team} ${isExitingRoute ? " animate__animated animate__fadeOutDown " : "animate__animated animate__fadeInUp"}`} 
                style={isExitingRoute ? { animationDelay:'0.4s',animationDuration:'0.3s' } : {animationDelay:'2.3s', animationDuration: '1s' }} >
                        <div className={styles.team_member}>Smart Insights with Intelligent Data</div>
                        <div className={styles.team_function}>
                        <p>Our AI solution, powered by LLM infrastructure, continuously gathers real-time data. Its intelligent search functionality sifts through vast online sources to uncover the most pertinent information, presenting it seamlessly to users in a curated feed. From obscure insights to the latest updates, our system ensures users access the most relevant and valuable content effortlessly.</p>
                        </div>
                    </div>
                </div>
                <div 
            className={`${styles.cards} ${isExitingRoute ? " animate__animated animate__fadeOut " : "animate__animated animate__fadeIn"}`}
            style={isExitingRoute ? {animationDelay:'0.4s',animationDuration:'0.3s'} : {animationDelay:'2.5s', animationDuration: '1s' }}
                >
                <div className={`${styles.horizontal_team} ${isExitingRoute ? " animate__animated animate__fadeOutDown " : "animate__animated animate__fadeInUp"}`} 
                style={isExitingRoute ? { animationDelay:'0.3s',animationDuration:'0.3s' } : {animationDelay:'2.7s',animationDuration: '1s' }} >
                        <div className={styles.team_member}>Agreements Ready in Seconds</div>
                        <div className={styles.team_function}>
                        <p>Agreementor utilizes advanced natural language processing to read and interpret agreements. It identifies inappropriate or risky clauses by comparing them against predefined criteria and legal standards. Additionally, it explains necessary amendments by highlighting potential pitfalls and recommending modifications for clarity and compliance.</p>
                        </div>
                    </div>
                </div>
            </>
        ) 
        :
        (
            
            <div 
                className={`${styles.responsiveCard} ${isExitingRoute ? " animate__animated animate__fadeOut " : "animate__animated animate__fadeIn"}`}
                style={isExitingRoute ? {animationDelay:'0.6s',animationDuration:'0.3s'} : {animationDelay:'1.5s', animationDuration: '1s' }}
                >
                    <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
                        <SwiperSlide>     
                            <div className={`${styles.horizontal_team} ${isExitingRoute ? " animate__animated animate__fadeOutDown" : "animate__animated animate__fadeInUp"}`} 
                            style={isExitingRoute ? { animationDelay:'0.5s',animationDuration:'0.3s' } : {animationDelay:'2.1s', animationDuration: '1s' }} >
                                    <div className={styles.team_member}>Holistic Data, Targeted Strategies</div>
                                    <div className={styles.team_function}>
                                    <p>Genarion’s GAI applications are purposefully crafted to redefine the boundaries of realism concerning needs and expectations. We are driven by the ambition to push beyond conventional limits, setting new standards for what AI can achieve.</p>
                                    </div>
                                </div>
                        </SwiperSlide>
                        <SwiperSlide>  
                            <div className={`${styles.horizontal_team} ${isExitingRoute ? " animate__animated animate__fadeOutDown " : "animate__animated animate__fadeInUp"}`} 
                            style={isExitingRoute ? { animationDelay:'0.4s',animationDuration:'0.3s' } : {animationDelay:'2.3s', animationDuration: '1s' }} >
                                    <div className={styles.team_member}>Forecast, Evaluate, Optimize</div>
                                    <div className={styles.team_function}>
                                    <p>Pre-game, our architecture forecasts outcomes by analyzing historical player and team behaviors, aiding coaches in devising strategic game plans. Post-game, it evaluates actual performance against predicted outcomes, offering insights to fine-tune tactics for future matches. This iterative process optimizes team strategies and enhances overall performance.</p>
                                    </div>
                                </div>
                        </SwiperSlide>
                        <SwiperSlide> 
                            <div className={`${styles.horizontal_team} ${isExitingRoute ? " animate__animated animate__fadeOutDown " : "animate__animated animate__fadeInUp"}`} 
                            style={isExitingRoute ? { animationDelay:'0.3s',animationDuration:'0.3s' } : {animationDelay:'2.7s',animationDuration: '1s' }} >
                                    <div className={styles.team_member}>Revolutionizing Scouting</div>
                                    <div className={styles.team_function}>
                                    <p>Genarion Sports AI revolutionizes scouting by analyzing player and team behaviors comprehensively. It identifies strengths, weaknesses, and patterns, aiding in strategic recruitment decisions. Coaches and scouts gain deeper insights into potential signings, ensuring a more informed and effective scouting process.</p>
                                    </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
            </div>
        )
    }
    </>
        )
}

export default Pricing;