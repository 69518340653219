import { FC, useEffect, useState } from 'react';
import styles from '../HomePage/video.module.css';
import useDelayRouteExit from 'delay-react-route-exit';

export interface IWindowWeight {
    width: number;
}

const PrivacyPolicy: FC<IWindowWeight> = ({ width }) => {

    const [isExitingRoute, setIsExitingRoute] = useState<boolean>();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useDelayRouteExit(1500, () => {
        setIsExitingRoute(true);
    });

    useEffect(() => {

        var iframe = document.getElementById('myiframe') as HTMLIFrameElement;

        if (iframe) {
            const iframeWindow = iframe.contentWindow;
            const iframeDocument = iframeWindow ? iframeWindow.document : null;

            iframe.onload = () => {

                if (iframeDocument) {
                    const body = iframeDocument.body;

                    if (body) {
                        body.style.fontFamily = "'LuxoraGoretskThin', sans-serif !important";
                        body.style.backgroundColor = '#c9c9c94a !important';
                        body.style.backdropFilter = 'blur(5px) !important';
                    }
                }
            }
        }

        if (width < 1023) {
            document.getElementById('responsiveCard')!.style.width = '360px';
        }
    }, [width])


    return (
        <>
            <div
                id='responsiveCard'
                className={`${styles.policycard} ${isExitingRoute ? " animate__animated animate__fadeOutDown " : "animate__animated animate__fadeInUp"}`}
                style={isExitingRoute ? { animationDelay: '0.6s', animationDuration: '0.3s', justifyContent: 'center', alignItems: 'center', overflowY: 'auto' } : { animationDelay: '1.5s', animationDuration: '1s', justifyContent: 'center', alignItems: 'center', overflowY: 'auto' }}
            >
                <div className={`${isExitingRoute ? " animate__animated animate__fadeOutLeft " : "animate__animated animate__fadeInLeft"}`}
                    style={isExitingRoute ? { animationDelay: '0.5s', animationDuration: '0.3s', textAlign: 'left' } : { animationDelay: '2.1s', animationDuration: '1s', textAlign: 'left' }} >
                    <p style={{ fontSize: '18px' }}>
                        <b>Genarion Privacy Policy</b><br></br>
                        <br></br>
                        <b>Effective Date:</b> October 1, 2024<br></br>
                        <b>Introduction</b><br></br>
                        Genarion is committed to protecting the integrity and privacy of your personal information. This Privacy Policy outlines how we collect, use, store, and disclose personal information through our website and services. Our services are intended for business and professional purposes, not for personal, family, or household use.<br></br>
                        If you have any questions or concerns about our use of your personal information, please contact us using the information provided at the end of this Privacy Policy.<br></br>
                        ________________________________________<br></br>
                        <b>Information We Collect</b><br></br>
                        •	<b>Information Provided Voluntarily:</b> You may provide information, such as contact details, account registration, or support requests.<br></br>
                        •	<b>Automatically Collected Information:</b> We may collect information like IP addresses, device type, browser type, geographic location, and how you interact with our website and services. Some of this data may be collected through cookies and similar technologies.<br></br>
                        •	<b>Information from Third Parties:</b> Occasionally, we may receive information about you from third parties where legally permitted.<br></br>
                        <b>How We Use Information</b><br></br>
                        Genarion may use your personal information to:<br></br>
                        •	Operate, maintain, and improve our website and services<br></br>
                        •	Personalize your experience<br></br>
                        •	Contact you for administrative purposes or respond to inquiries<br></br>
                        •	Analyze trends and monitor usage to improve our offerings<br></br>
                        •	Send updates or promotional content, in accordance with your preferences<br></br>
                        <b>Information Sharing and Disclosure</b><br></br>
                        We may disclose personal information to:<br></br>
                        •	<b>Service Providers and Partners</b> who assist us in delivering and maintaining our services<br></br>
                        •	<b>Legal Authorities or Third Parties</b> as required to comply with laws or protect rights and safety<br></br>
                        •	<b>Potential Buyers</b> in cases of business reorganization or acquisition<br></br>
                        •	<b>Others with Your Consent</b><br></br>
                        <b>Data Security</b><br></br>
                        We use technical and organizational measures to safeguard your data. While we strive to protect your information, no method is entirely secure.<br></br>
                        <b>International Data Transfers</b><br></br>
                        Your personal information may be transferred to countries with different data protection laws. We implement appropriate safeguards to ensure your data remains protected.<br></br>
                        <b>Data Retention</b><br></br>
                        We retain personal information as long as necessary for legitimate business purposes or legal requirements. If data is processed on behalf of another organization, retention follows that organization’s instructions.<br></br>
                        <b>Your Data Protection Rights</b><br></br>
                        Depending on applicable laws, you may have rights to:<br></br>
                        •	<b>Access, correct, or delete</b> your personal information<br></br>
                        •	<b>Object to or restrict processing</b><br></br>
                        •	<b>Withdraw consent</b> where processing is based on consent<br></br>
                        •	<b>File a complaint</b> with a relevant authority<br></br>
                        We will respond to data rights requests in accordance with legal requirements.<br></br>
                        <b>Updates to this Privacy Policy</b><br></br>
                        We may update this policy periodically to reflect changes in our practices or legal requirements. Significant updates will be communicated to you.<br></br>
                        <b>Contact Us</b><br></br>
                        For any questions about our Privacy Policy or data practices, please contact us at:<br></br>
                        <br></br>
                        <b>GENARİON BİLİŞİM TEKNOLOJİ SANAYİ VE TİCARET ANONİM ŞİRKETİ</b><br></br>
                        <br></br>
                        <b>Address:</b> MÜEYYETZADE MAH. KEMERALTI CAD. BAU BAHÇEŞEHIR , ÜNIVERISTESI NO: 24 İÇ KAPI NO: 3 BEYOGLU, Istanbul, Istanbul, 34425, Turkey<br></br>
                        <b>Email:</b> hello@genarion.com<br></br>
                        <b>Phone:</b> +905309114264<br></br>

                    </p>
                </div>
            </div>
        </>
    )
}


export default PrivacyPolicy;